import { C, Dictionary } from 'app/base/common';
import env from 'app/base/env';
import { FulfillmentFormatType, MediaType } from 'app/base/interfaces';
import En_json from 'res/text/en.json';

export class Constants {
  /**
   * Email regex pattern for email validation
   */
  public static readonly EMAIL_PATTERN = '[^@\\s]+@[^@\\s]+\\.[^@\\s]+';

  public static readonly SURVEY_LINK = 'https://survey.alchemer.com/s3/6061372/LexisNexis-Patron-Feedback-2020';
  public static readonly SURVEY_TRIGGER_MILLISECONDS = 30 * 1000;
  public static readonly SURVEY_CLOSED_RESPITE_DAYS = 45;
  public static readonly SURVEY_TAKEN_RESPITE_DAYS = 90;

  public static readonly ALERT_SURVEY_LINK = 'https://survey.alchemer.com/s3/8159463/LNDLappsurvey';

  public static readonly FORMAT_MAPPINGS: Dictionary<MediaType> = {
    ebook: 'book',
    audiobook: 'audiobook',
    magazine: 'magazine',
    video: 'video'
  };

  public static readonly LEXIS_PUBLISHER_ACCOUNT_ID = '8604';


  public static readonly FULFILLMENT_FORMAT_TYPES: Dictionary<FulfillmentFormatType> = {
    kindle: {
      id: 'kindle',
      thunderId: 'ebook-kindle',
      name: 'Kindle'
    },
    ade: {
      id: 'ade',
      thunderId: 'ebook-pdf-adobe',
      name: 'Adobe PDF'
    },
    pdf: {
      id: 'pdf',
      thunderId: 'ebook-pdf-open',
      name: 'PDF'
    },
    video: {
      id: 'video',
      thunderId: 'video-streaming',
      name: 'Video'
    }
    // 'kobo': {
    //   id: 'kobo',
    //   thunderId: 'ebook-kobo',
    //   name: 'Kobo'
    // }
  };
  public static readonly SUPPORTED_FORMATS = [
    'audiobook-overdrive',
    'ebook-overdrive',
    'magazine-overdrive',
    'ebook-pdf-adobe',
    'ebook-pdf-open',
    'video-streaming'
  ];
  public static readonly TEXTS = En_json;


  public static readonly APP_NAME = 'LexisNexis Digital Library';
  public static readonly PRIVACY_POLICY_PATH = 'https://company.cdn.overdrive.com/policies/privacy-policy.htm';
  public static readonly COOKIE_POLICY_PATH = 'https://company.cdn.overdrive.com/policies/cookie-policy.htm';
  public static readonly TERMS_AND_COND_PATH = 'https://www.lexisnexis.com/en-us/terms/publications-services-agreement.page';
  public static readonly COPYRIGHT_PATH = 'https://company.cdn.overdrive.com/policies/copyright.htm';
  public static readonly RELEASENOTE_PATH = 'https://help.lexisdl.com/release-notes.htm';

  public static readonly HELP_PATHS = {
    ANNOTATIONS: 'https://help.lexisdl.com/3016.htm',
    COPYING: 'https://help.lexisdl.com/3039.htm',
    EXPIRING: 'https://help.lexisdl.com/3024.htm',
    EXPORTS: 'https://help.lexisdl.com/3023.htm',
    NTC: 'https://help.lexisdl.com/3038.htm',
    GET_STARTED: 'https://help.lexisdl.com/3000.htm',
    HOLDS: 'https://help.lexisdl.com/3022.htm',
    HOME: 'https://help.lexisdl.com',
    ALERTS: 'https://help.lexisdl.com/3042.htm',
    SUBSCRIPTIONS: 'https://help.lexisdl.com/3043.htm',
    TAGS: 'https://help.lexisdl.com/3014.htm'
  };


  public static text(label: string, warnIfNotFound?: boolean): string {
    let text = Constants.TEXTS[label as keyof typeof En_json];
    if (!C.isString(text) && warnIfNotFound) {
      text = '{{missing text: ' + label + '}}';
    }

    return text;
  }


  public static get formats(): string[] {
    const out: string[] = [];
    for (const mt in Constants.FORMAT_MAPPINGS) {
      out.push(Constants.FORMAT_MAPPINGS[mt]);
    }

    return out;
  }


  public static toMediaType(mediaType: string): MediaType {
    return Constants.FORMAT_MAPPINGS[mediaType];
  }


  public static toThunderMediaType(mediaType: string): string {
    return C.invertObject(Constants.FORMAT_MAPPINGS)[mediaType];
  }


  public static idToFulfillmentFormatType(thunderId: string): FulfillmentFormatType | null {
    for (const id in Constants.FULFILLMENT_FORMAT_TYPES) {
      const fft = Constants.FULFILLMENT_FORMAT_TYPES[id];
      if (fft && fft.thunderId === thunderId) {
        return fft;
      }
    }

    return null;
  }


  public static assetPath(subpath: string): string {
    return [
      env.ELROND_ENV === 'dev' ? '' : `/elrond-${env.VERSION_NUMBER}`,
      'assets',
      subpath
    ].join('/');
  }


  public static assetURL(subpath: string): string {
    return env.ROOT_URI + Constants.assetPath(subpath);
  }
}

/**
 * A highlight color
 */
export enum HighlightColor {
  /** Red Light */
  RL,
  /** Red Strong */
  RS,
  /** Orange Light */
  OL,
  /** Orange Strong */
  OS,
  /** Yellow Light */
  YL,
  /** Yellow Strong */
  YS,
  /** Gold Light */
  GL,
  /** Gold Strong */
  GS,
  /** Teal Light */
  TL,
  /** Teal Strong */
  TS,
  /** Blue Light */
  BL,
  /** Blue Strong */
  BS,
  /** Violet Light */
  VL,
  /** Violet Strong */
  VS
}

export type HighlightColorGroup = keyof typeof HighlightColor;

export const seriesCoverSizes = {
  alertShade: 3.7,
  seriesCardCompact: 4.5,
  titleDetails: 6,
  alertDetails: 7.5,
  seriesCard: 8.625,
  seriesDetails: 15.5
};
