<template>
  <ol
    :class="$style.list"
  >
    <li
      v-for="(result, index) in results"
      :key="index"
    >
      <SearchThisTitleResult
        :result="result"
        :title="titles[result.titleId.toString()]"
        @close="$emit('close')"
      />
    </li>
  </ol>
</template>

<script lang="ts">
import { MatchedTitle } from 'app/base/hudson';
import SearchThisTitleResult from 'app/components/SearchThisTitleResult.vue';
import { TitleRecord } from 'app/models/title';
import { Dictionary } from 'lib/common/dictionary';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchThisTitleResultList',
  components: { SearchThisTitleResult },
  props: {
    results: {
      type: Array as () => MatchedTitle[],
      required: true
    },
    titles: {
      type: Object as () => Dictionary<TitleRecord>,
      default: () => ({})
    }
  },
  emits: [
    'close'
  ]
});
</script>

<style module>
.list > li + li {
  border-top: 1px solid var(--c-dark-gray);
  margin-top: 2rem;
  padding-top: 1.5rem;
}
</style>
