<template>
  <div :class="$style.container">
    <p :class="$style.exclamation">
      {{ $t('alert.survey.exclamation') }}
    </p>
    <p>
      {{ $t('alert.survey.request') }}
    </p>
    <div :class="$style.actions">
      <a
        :class="[$style.link, 'focus-outline', 'dark']"
        :href="link"
        target="_blank"
        :aria-label="$t('alert.survey.linkAriaLabel')"
        @click="acknowledgeAndAnnounce"
      >
        {{ $t('alert.survey.link') }}
      </a>
      <button
        :class="[$style.dismissButton, 'focus-outline']"
        @click="acknowledgeAndAnnounce"
      >
        {{ $t('general.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Constants } from 'app/base/constants';
import { useAlertsSurvey } from 'app/functions/use-alerts-survey';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { AlertsSurveySymbol } from 'app/keys/injection-keys';
import { defineComponent, inject } from 'vue';


export default defineComponent({
  setup: () => {
    const link = Constants.ALERT_SURVEY_LINK;
    const { t } = useI18n();

    const { acknowledgeSurvey } = inject(AlertsSurveySymbol, useAlertsSurvey());

    const acknowledgeAndAnnounce = async () => {
      await acknowledgeSurvey();
      announceMessage(t('alert.survey.dismissAnnouncement'));
    };

    return {
      acknowledgeAndAnnounce,
      link
    };
  }
});
</script>

<style module>
  .container {
    display: grid;
    grid-template-rows: repeat(auto, 3);
    gap: 1rem;
    padding: 1rem;

    border-radius: var(--form-border-radius);
    background-color: var(--c-primary-blue);
    color: var(--c-white);
  }

  .exclamation {
    font-weight: var(--fw-bold);
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .link {
    border-radius: var(--form-border-radius);
    background-color: var(--c-white);
    color: var(--c-primary-blue);
    padding: 0.5rem 1rem;
  }

  .dismiss-button {
    text-decoration: underline;
    padding: 0.5rem 1rem;
  }
</style>
