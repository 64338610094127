<template>
  <div :class="$style.content">
    <AlertListEmptyState
      v-if="alertsWithMetadata.length === 0"
    />

    <transition-group
      v-else
      tag="ol"
      role="list"
      :aria-label="$t('alert.header')"
      name="list"
      :enterFromClass="$style.listEnter"
      :enterActiveClass="$style.listEnterActive"
      :leaveActiveClass="$style.listLeaveActive"
      :leaveToClass="$style.listLeaveTo"
      :class="$style.list"
    >
      <li
        v-for="(alert, index) in alertsSorted"
        :key="alert.id"
        :class="$style.listItem"
      >
        <AlertCard
          :alertWithMetadata="alert"
          @view-details="(alert) => $emit('view-details', alert)"
          @clear="clearAlert(alert.id, index)"
        />
      </li>
    </transition-group>
  </div>
</template>

<script lang='ts'>
import { Constants } from 'app/base/constants';
import AlertListEmptyState from 'app/components/AlertListEmptyState.vue';
import AlertCard from 'app/components/cards/AlertCard.vue';
import { AlertWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { PropType, computed, defineComponent, watch } from 'vue';


export default defineComponent({
  components: {
    AlertCard,
    AlertListEmptyState
  },
  props: {
    alertsWithMetadata: {
      type: Array as PropType<AlertWithMetadata[]>,
      required: true
    }
  },
  emits: [
    'clear',
    'update',
    'view-details'
  ],
  setup: (props, ctx) => {
    const helpLink = Constants.HELP_PATHS.ALERTS;
    const { windowWidth } = useWindowSize();
    const compact = computed(() => windowWidth.value > Breakpoint.HideNav);
    const hasAlerts = computed(() => props.alertsWithMetadata.length > 0);

    watch(() => props.alertsWithMetadata, () => {
      ctx.emit('update');
    });

    const sortByTimestamp = (a: AlertWithMetadata, b: AlertWithMetadata) => {
      if (!a) { return -1; }
      if (!b) { return 1; }

      return b.timestamp - a.timestamp;
    };

    const sortByReviewed = (a: AlertWithMetadata, b: AlertWithMetadata) => {
      if (!a) { return -1; }
      if (!b) { return 1; }

      if (a.reviewed && !b.reviewed) { return 1; }
      if (!a.reviewed && b.reviewed) { return -1; }

      return 0;
    };
    const alertsSorted = computed(() => props.alertsWithMetadata.slice().sort(sortByTimestamp).sort(sortByReviewed));

    const clearAlert = (id: number, index: number) => {
      ctx.emit('clear', id);

      // shift keyboard focus to sibling element
      const sibling = alertsSorted.value[index + 1] || alertsSorted.value[index - 1];
      if (sibling) {
        (document.querySelector(`#alert-review-${sibling.id}`) as HTMLElement)?.focus();
      }
    };

    return {
      alertsSorted,
      helpLink,
      compact,
      hasAlerts,

      clearAlert
    };
  }
});
</script>

<style module>

.content {
  width: 100%;
}

.content .list-item {
  padding: 0.5rem 0;
  margin: 0.25rem;
}

.content .list-item:not(:last-child) {
  border-bottom: 1px solid var(--c-dark-gray);
}

/* enter/leave transitions for each alert */

.list {
  overflow: hidden;
  position: relative;
}

.list-item {
  transition: opacity .5s ease, transform .5s ease;
  position: relative;
}

.list-leave-active {
  position: absolute;
  /* to keep the grid element fully sized */
  width: calc(100% - 1rem);
  box-sizing: border-box; /* to ignore padding */
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
