import { APP } from 'app/base/app';
import { ref } from 'vue';


export function useAlertsSurvey() {
  const acknowledged = ref<boolean | undefined>();
  const loading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const updateAcknowledged = <T extends (...args: any[]) => Promise<boolean>>(func: T) => async (...args: Parameters<T>) => {
    let succeeded = false;

    try {
      loading.value = true;

      if (!APP.patron.accountId) { throw new Error; }

      const result = await func(...args);

      acknowledged.value = result;
      error.value = false;

      succeeded = true;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }

    return succeeded;
  };

  const checkSurvey = updateAcknowledged(async () => {
    const response = await APP.services.samwise.checkSurvey();
    if (response === null) { throw new Error; }

    return response;
  });

  const acknowledgeSurvey = updateAcknowledged(async () => {
    await APP.services.samwise.acknowledgeSurvey();

    return true;
  });

  const clearSurvey = updateAcknowledged(async () => {
    await APP.services.samwise.clearSurvey();

    return false;
  });

  return {
    acknowledged,
    loading,
    error,

    checkSurvey,
    acknowledgeSurvey,
    clearSurvey
  };
}
