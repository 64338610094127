<template>
  <section :class="$style.container">
    <component
      :is="headerTag"
      v-if="label"
      :class="$style.header"
    >
      {{ label }}
    </component>
    <ol :class="$style.list">
      <li
        v-for="subject in subjects"
        :key="subject.id"
        :class="$style.subject"
      >
        <router-link
          :class="$style.link"
          :to="subject.route"
        >
          <span :class="$style.name">{{ subject.name }}</span>
          <span :class="$style.count">{{ subject.count }}</span>
        </router-link>
      </li>
    </ol>
  </section>
</template>

<script lang='ts'>
import { SubjectCollection } from 'app/models/subject';
import { defineComponent } from 'vue';


export default defineComponent({
  props: {
    subjects: {
      type: Array as () => SubjectCollection[],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    headerTag: {
      type: String as () => 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
      required: false,
      default: 'h1'
    }
  }
});
</script>

<style module>
.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.header {
  display: inline-block;
  color: var(--c-dark-black);
  font-size: var(--fs-medium-head);
  font-weight: var(--fw-medium);
  padding-bottom: .5rem;
  border-bottom: 2px solid var(--c-light-black);
  margin-bottom: 1.5rem;
}

.subject {
  padding: 0.5rem 0.5rem 0.5rem 0;
  box-sizing: border-box;
}

.link {
  composes: focus-outline from global;
}

.name {
  composes: linked from global;
  color: var(--c-primary-blue);
  margin-right: 0.5rem;
}

.count {
  color: var(--c-light-black);
}

@media screen and (max-width: 768px /* px-vp-tablet */) {
  .list {
    grid-template-columns: repeat(2 ,1fr);
  }
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .list {
    grid-template-columns: 1fr;
  }
}
</style>
