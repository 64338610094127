<template>
  <template v-if="state.state === 'loading'">
    <Icon
      :class="$style.loading"
      :aria-label="$t('general.loading')"
      name="spinner"
    />
  </template>

  <template v-else-if="state.state === 'success'">
    <template v-if="state.response.list.items.length > 0">
      <ol
        :class="[$style.cardList, 'list-grid']"
        role="list"
      >
        <template v-if="state.response.searchType === 'series'">
          <li
            v-for="item in state.response.list.items"
            :key="item.id"
          >
            <SeriesCard :series="item" />
          </li>
        </template>
        <template v-else>
          <li
            v-for="item in state.response.list.items"
            :key="item.slug"
          >
            <TitleCard :title="item" />
          </li>
        </template>
      </ol>
      <Pagination
        v-if="state.response.list.pagination.total > 1"
        :class="$style.pagination"
        :currentPage="state.response.list.pagination.current"
        :totalPages="state.response.list.pagination.total"
        @update:currentPage="(page) => $emit('update:currentPage', page)"
      />
    </template>

    <template v-else>
      <p>
        {{ $t('list.noMatches') }}
      </p>
    </template>
  </template>

  <template v-else>
    <p>
      {{ $t('list.error') }}
    </p>
  </template>
</template>

<script lang="ts">
import { SearchType } from 'app/base/search';
import SeriesCard from 'app/components/cards/SeriesCard.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import Pagination from 'app/components/Pagination.vue';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { SearchState } from 'app/functions/use-search';
import { ref, watch } from 'vue';


export default {
  name: 'PaginatedCardList',
  components : {
    Pagination,
    TitleCard,
    SeriesCard
  },
  props: {
    state: {
      type: Object as () => SearchState<SearchType>,
      required: true
    },
    activeTab: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'update:currentPage'
  ],
  setup: (props) => {
    const { t } = useI18n();
    const initialLoad = ref(true);
    watch([() => props.state.state, () => props.activeTab], () => {
      if (props.activeTab) {
        if (props.state.state === 'success' && props.state.response.list.pagination.total > 1) {
          if (initialLoad.value) {
            initialLoad.value = false;
          } else {
            announceMessage(t('list.pageChange', {
              page: props.state.response.list.pagination.current.toString(),
              total: props.state.response.list.pagination.total.toString()
            }));
          }
        } else if (props.state.state === 'loading') {
          setTimeout(() => {
            if (props.state.state === 'loading') {
              announceMessage(t('general.loading'), 'assertive');
            }
          }, 2000);
        } else if (props.state.state === 'error') {
          announceMessage(t('list.error'));
        }
      }
    }, { immediate: true });
  }
};
</script>

<style module>
.card-list {
  --grid-layout-gap: 1.5rem;
  --grid-column-count: 3;
  --grid-item-min-width: var(--px-card-min-width);
}

.pagination {
  margin-top: 2.5rem;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 5rem auto;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .card-list {
    --grid-item-min-width: 1fr;
  }
}
</style>
