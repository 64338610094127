import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SeriesCard = _resolveComponent("SeriesCard")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return ($props.state.state === 'loading')
    ? (_openBlock(), _createBlock(_component_Icon, {
        key: 0,
        class: _normalizeClass(_ctx.$style.loading),
        "aria-label": _ctx.$t('general.loading'),
        name: "spinner"
      }, null, 8, ["class", "aria-label"]))
    : ($props.state.state === 'success')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($props.state.response.list.items.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("ol", {
                  class: _normalizeClass([_ctx.$style.cardList, 'list-grid']),
                  role: "list"
                }, [
                  ($props.state.response.searchType === 'series')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.state.response.list.items, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: item.id
                        }, [
                          _createVNode(_component_SeriesCard, { series: item }, null, 8, ["series"])
                        ]))
                      }), 128))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.state.response.list.items, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: item.slug
                        }, [
                          _createVNode(_component_TitleCard, { title: item }, null, 8, ["title"])
                        ]))
                      }), 128))
                ], 2),
                ($props.state.response.list.pagination.total > 1)
                  ? (_openBlock(), _createBlock(_component_Pagination, {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.pagination),
                      currentPage: $props.state.response.list.pagination.current,
                      totalPages: $props.state.response.list.pagination.total,
                      "onUpdate:currentPage": _cache[0] || (_cache[0] = (page) => _ctx.$emit('update:currentPage', page))
                    }, null, 8, ["class", "currentPage", "totalPages"]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('list.noMatches')), 1))
        ], 64))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('list.error')), 1))
}