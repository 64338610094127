<template>
  <div :class="$style.actions">
    <router-link
      :to="link"
      :class="$style.action"
    >
      {{ $t('series.view') }}
    </router-link>

    <button
      ref="overflow"
      :class="$style.overflow"
      :aria-label="$t('series.contextMenu.header')"
      aria-haspopup="dialog"
      @click="showContextMenu = true"
    >
      <Icon name="overflow" />
    </button>

    <SeriesCardContextMenu
      v-if="showContextMenu"
      :reference="overflow"
      :series="series"
      :subscribeText="subscribeTextLong"
      @close="showContextMenu = false"
      @show:tableofcontents="showTableOfContents = true"
      @toggle:subscription="toggleSubscription"
    />

    <TableOfContentsSidebar
      v-if="showTableOfContents"
      :item="series"
      @close="showTableOfContents = false"
    />
  </div>
</template>

<script lang='ts'>
import SeriesCardContextMenu from 'app/components/contextMenus/SeriesCardContextMenu.vue';
import { useSeriesSubscription } from 'app/functions/use-subscription-interaction';
import { Series } from 'app/models/series';
import { defineComponent, ref } from 'vue';
import { RouteLocation } from 'vue-router';
import TableOfContentsSidebar from '../TableOfContentsSidebar.vue';

export default defineComponent({
  name: 'SeriesCardOptions',
  components: {
    SeriesCardContextMenu,
    TableOfContentsSidebar
  },
  props: {
    series: {
      type: Object as () => Series,
      required: true
    },
    link: {
      type: Object as () => RouteLocation | undefined,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const showContextMenu = ref(false);
    const overflow = ref<HTMLElement | null>(null);
    const showTableOfContents = ref(false);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useSeriesSubscription(props.series, 'series card');


    return {
      overflow,
      showContextMenu,
      showTableOfContents,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
</script>

<style module>
.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: -1rem;
}

.action {
  display: flex;
  place-content: center;

  font-weight: var(--fw-bold);
  font-size: var(--fs-body);
  width: max-content;

  composes: focus-outline from global;

  min-width: calc(var(--rem-cover-width) - 1rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem;
  color: var(--c-primary-blue);
}

.overflow {
  padding: 0 1.125rem;
  line-height: 0;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;
}

.overflow:hover, .overflow:focus {
  border-radius: 1.5rem;
  background-color: var(--c-light-gray);
}

.overflow svg {
  height: 1.25rem;
  width: 0.625rem;
  fill: var(--c-primary-blue);
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .actions {
    margin-top: 1rem;
  }
}
</style>
