<template>
  <ol v-if="priorReleases">
    <li
      v-for="release in priorReleases"
      :key="release.title.slug"
      :class="$style.release"
    >
      <table :class="$style.releaseInfo">
        <tr v-if="release.release">
          <th
            scope="row"
            :class="['metadata-label', 'ellipsis']"
          >
            {{ $t('title.release') }}
          </th>
          <td class="metadata">
            {{ release.release }}
          </td>
        </tr>

        <tr v-if="release.releaseDate">
          <th
            scope="row"
            :class="['metadata-label', 'ellipsis']"
          >
            {{ $t('title.releaseDate') }}
          </th>
          <td class="metadata">
            {{ release.releaseDate }}
          </td>
        </tr>
      </table>

      <div :class="$style.releaseActions">
        <TitleActionButton
          :title="release.title"
          :parent="title"
          :class="['focus-outline', 'linked']"
        />

        <router-link
          v-if="release.numAnnotations"
          :to="releaseNotesDetailsLink(release.title)"
          :class="['focus-outline', 'linked']"
        >
          {{ $t('title.notesAction', { n: release.numAnnotations }) }}
        </router-link>
      </div>
    </li>
  </ol>
  <p
    v-else
    :class="$style.loading"
  >
    <Icon
      name="spinner"
      :aria-label="$t('general.loading')"
    />
  </p>
</template>

<script lang='ts'>
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { defineComponent, onMounted, ref } from 'vue';

type PriorReleaseInfo = {
  release?: string;
  releaseDate?: string;
  title: TitleRecord;
  numAnnotations?: number;
};

export default defineComponent({
  name: 'TitlePriorReleaseList',
  components: {
    TitleActionButton
  },
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  setup: (props) => {
    const priorReleases = ref<PriorReleaseInfo[] | null>(null);

    onMounted(async () => {
      const releases = await props.title.getPriorReleases();

      priorReleases.value = releases
        .map((releaseTitle) => {
          return {
            title: releaseTitle,
            release: releaseTitle.lexisMetadata?.release,
            releaseDate: releaseTitle.lexisMetadata?.releaseDate,
            numAnnotations: releaseTitle.annotationsForRelease()?.length || 0
          };
        });
    });

    const releaseNotesDetailsLink = (title: TitleRecord) => {
      return {
        name: RouteName.NoteDetails,
        params: {
          titleSlug: title.lexisMetadata.parent
        },
        query: {
          release: title.slug
        }
      };
    };

    return {
      priorReleases,
      releaseNotesDetailsLink
    };
  }
});
</script>

<style module>
.loading {
  width: 100%;
  height: 10rem;
  margin: auto;
  position: relative;
}

.loading svg {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.release {
  padding: 0.75rem 0;
}

.release:not(:last-child) {
  border-bottom: 1px solid var(--c-dark-gray);
}

.release-info {
  display: flex;
  flex-wrap: wrap;
}

.release-info th {
  color: var(--c-light-black);
  padding-right: 0.5rem;
}

.release-info td {
  padding-right: 1rem;
}

.release-actions {
  padding: 0.75rem 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 1rem;
  align-items: center;
  font-size: var(--fs-metadata);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

.release-actions a, button {
  color: var(--c-primary-blue);
}
</style>
