<template>
  <article
    :class="{
      [$style.card]: true,
      [$style.borderless]: borderless,
      [$style.compact]: compact
    }"
    :aria-label="fullTitle"
  >
    <div :class="[$style.info, showInfoFocus ? $style.focused : '']">
      <router-link
        v-if="link"
        :aria-hidden="true"
        :to="link"
        :class="$style.cover"
        tabindex="-1"
      >
        <slot name="cover"></slot>
      </router-link>

      <div
        v-else
        :class="$style.cover"
      >
        <slot name="cover"></slot>
      </div>

      <div :class="$style.meta">
        <div :class="$style.titlingContainer">
          <div
            v-if="link"
            :class="$style.titling"
          >
            <h2
              ref="titleName"
              :class="[$style.title, $style.clamped2]"
              v-html="item.title"
            >
            </h2>
            <p
              v-if="item.subtitle"
              ref="subtitle"
              :class="[$style.secondary, $style.clamped]"
              v-html="item.subtitle"
            >
            </p>
            <p
              v-if="item.authors"
              ref="authors"
              :class="[$style.secondary, $style.clamped]"
              v-html="item.authors"
            >
            </p>
            <router-link
              v-slot="{ href, navigate }"
              :to="link"
              custom
            >
              <a
                :href="href"
                :class="$style.link"
                @click="navigate"
                @focus="showInfoFocus = true"
                @blur="showInfoFocus = false"
              >

                <p class="visually-hidden">
                  {{ $t('title.contextMenu.viewDetailsPage') }}
                </p>
              </a>
            </router-link>
          </div>
          <div
            v-else
            :class="$style.titling"
          >
            <div :class="$style.titleGroup">
              <span
                v-if="showSeriesBadge"
                class="series-badge"
              >
                {{ $t('series.label') }}
              </span>
              <h2
                ref="titleName"
                :class="[$style.title, $style.clamped2]"
                v-html="item.title"
              >
              </h2>
            </div>
            <p
              v-if="item.subtitle"
              ref="subtitle"
              :class="[$style.secondary, $style.clamped]"
              v-html="item.subtitle"
            >
            </p>
            <p
              v-if="item.authors"
              ref="authors"
              :class="[$style.secondary, $style.clamped]"
              v-html="item.authors"
            >
            </p>
          </div>

          <div
            v-if="showExpansionButton"
            :class="$style.expansionButtonContainer"
            aria-hidden="true"
          >
            <button
              type="button"
              :class="$style.expansionButton"
              :aria-label="$t('title.expansion.expand')"
              @click="showExpansion = true"
            >
              <Icon name="truncation-expander" />
            </button>
          </div>
        </div>


        <table
          v-if="item.metadata"
          :class="$style.metadata"
        >
          <caption class="visually-hidden">
            {{ $t('title.metadata.description') }}
          </caption>

          <tbody>
            <tr
              v-for="meta in item.metadata"
              :key="meta.label"
            >
              <th
                :class="$style.metadataLabel"
                scope="row"
              >
                {{ $t(meta.label) }}
              </th>
              <td>{{ meta.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="showActions"
      :class="$style.actions"
    >
      <slot name="actions"></slot>
    </div>

    <FocusTrap
      v-if="showExpansion"
      @deactivate="showExpansion = false"
    >
      <div
        v-if="showExpansion"
        :class="$style.expansion"
      >
        <button
          type="button"
          :class="$style.expansionDismiss"
          :aria-label="$t('title.expansion.collapse')"
          @click="showExpansion = false"
        >
          <Icon name="dismiss" />
        </button>

        <h3
          :class="$style.title"
          v-html="item.title"
        >
        </h3>
        <p
          v-if="item.subtitle"
          :class="$style.secondary"
          v-html="item.subtitle"
        >
        </p>
        <p
          v-if="item.authors"
          :class="$style.secondary"
          v-html="item.authors"
        >
        </p>
      </div>
    </FocusTrap>
  </article>
</template>

<script lang='ts'>
import FocusTrap from 'app/components/FocusTrap.vue';
import { useClamped } from 'app/functions/use-clamped';
import { computed, defineComponent, ref } from 'vue';
import { RouteLocation } from 'vue-router';

export type CardMetadata = {
  label: string;
  value: string;
};

/**
 * The basic building block for a card
 */
export type CardableItem = {
  id: string;
  title: string;
  subtitle: string;
  authors: string;
  metadata: CardMetadata[];
};

/**
 * @desc - The base card component
 */
export default defineComponent({
  components: {
    FocusTrap
  },
  props: {
    /**
     * @param item - The item to display in card form
     */
    item: {
      type: Object as () => CardableItem,
      required: true
    },
    /**
     * @param link - The location this card should direct to
     * @example { name: "TitleDetails", params: { titleSlug: "1234" } }
     */
    link: {
      type: Object as () => RouteLocation,
      default: undefined
    },
    showExpander: {
      type: Boolean,
      default: true
    },
    /**
     * Display without card border and shadow
     */
    borderless: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    showSeriesBadge: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const showInfoFocus = ref(false);

    const titleName = ref<HTMLElement | null>(null);
    const { clamped: titleClamped } = useClamped(titleName);
    const subtitle = ref<HTMLElement | null>(null);
    const { clamped: subtitleClamped } = useClamped(subtitle);
    const authors = ref<HTMLElement | null>(null);
    const { clamped: authorsClamped } = useClamped(authors);
    const showExpansionButton = computed(() => props.showExpander && (titleClamped.value || subtitleClamped.value || authorsClamped.value));
    const showExpansion = ref(false);
    const showActions = computed(() => !!ctx.slots.actions);
    const fullTitle = (`${props.item.title} ${props.item.subtitle || ''}`).trim();

    return {
      fullTitle,
      showActions,
      showExpansion,
      showExpansionButton,
      showInfoFocus,
      authors,
      subtitle,
      titleName
    };
  }
});
</script>

<style module>
.card {
  position: relative;
  padding: 1rem;
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  border-radius: var(--form-border-radius);
  color: var(--c-dark-black);
  --rem-cover-height: 11.5rem;
  --rem-cover-width: calc(3 / 4 * var(--rem-cover-height));
}

.compact {
  --rem-cover-height: 6rem;
}

.cover {
  width: var(--rem-cover-width);
  height: var(--rem-cover-height);
}

.card.borderless {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid var(--c-dark-gray);
  border-radius: 0;
  padding: 1rem 0;
}

.info {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 4px;
  padding-bottom: 8px;
  position: relative;
}

.focused {
  border-radius: 0.25rem;
  composes: focus-outline-within from global;
}

.titling-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.titling {
  position: relative;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.link:focus {
  outline: none;
  box-shadow: none;
}

.title-group {
  display: flex;
}

.title {
  font-weight: var(--fw-bold);
}

.secondary {
  color: var(--c-dark-black);
}

.title,
.secondary,
.authors,
.expansion-button {
  margin-bottom: 0.25rem;
}

.clamped {
  --line-clamp: 1;
  composes: line-clamp from global;
}

.clamped-2 {
  --line-clamp: 2;
  composes: line-clamp from global;
}

.expansion-button-container {
  align-self: end;
  line-height: 0;
  margin-right: 0.375rem;
}

.expansion-button {
  padding: 0.125rem;
}

.expansion-button svg {
  width: 1rem;
  height: 1rem;
  fill: var(--c-black);
}

.metadata {
  font-size: var(--fs-body);
  font-weight: var(--fw-regular);
  line-height: var(--lh-body);
  text-align: left;
  margin-top: 0.5rem;
  color: var(--c-darkish-black);

  width: 100%;
  max-width: max-content;
}

.metadata th {
  padding-right: 1rem;
  white-space: nowrap;
}

.metadata-label {
  color: var(--c-darkish-black);
}

.actions {
  margin-top: 1.5rem;
}

.expansion {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  box-sizing: border-box;
  background: var(--c-white);
  border: 1px solid var(--c-card-border);
  border-radius: var(--form-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 1px 6px 0px var(--c-shadow);
  display: flex;
  flex-direction: column;
}

.expansion-dismiss {
  padding: 0.25rem;
  line-height: 0;
  position: absolute;
  right: 1rem;
}

.expansion-dismiss svg {
  fill: var(--c-light-black);
  width: 0.75rem;
  height: 0.75rem;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .card {
    --rem-cover-height: 6rem;
  }
}

@media screen and (max-width: 360px /* px-vp-very-narrow */) {
  .card {
    padding: .5rem;
  }

  .info {
    grid-template-columns: 1fr;
  }

  .metadata th {
    white-space: initial;
  }
}
</style>

