<template>
  <div :class="$style.container">
    <div
      v-if="showTitle"
      :class="$style.titleContainer"
    >
      <p
        :class="$style.title"
        v-html="title.title"
      ></p>
      <p
        v-if="title.subtitle"
        v-html="title.subtitle"
      ></p>
    </div>
    <p v-if="state === 'loading'">
      {{ $t('title.tableOfContents.loading') }}
    </p>
    <p v-else-if="state === 'failed'">
      {{ $t('title.tableOfContents.error') }}
    </p>
    <template v-else-if="toc.chapters">
      <div
        v-if="expandable"
        :class="$style.actions"
      >
        <button
          :class="$style.action"
          @click="expand"
        >
          {{ $t('title.tableOfContents.expandAll') }}
        </button>
        <span
          :aria-hidden="true"
          :class="$style.separator"
        ></span>
        <button
          :class="$style.action"
          @click="collapse"
        >
          {{ $t('title.tableOfContents.collapseAll') }}
        </button>
      </div>
      <ol :class="$style.chapterList">
        <li
          v-for="chapter in toc.chapters"
          :key="chapter.path"
          :class="$style.row"
        >
          <TableOfContentsChapter
            :ref="setItemRef"
            :title="title"
            :loan="loan"
            :chapter="chapter"
            :maxDepth="3"
            :linkable="linkable"
          />
        </li>
      </ol>
    </template>
    <!-- Fallback to the Metadata ToC -->
    <div v-else>
      <div v-html="fallback"></div>
    </div>
  </div>
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import TableOfContentsChapter from 'app/components/TableOfContentsChapter.vue';
import { useForRefs } from 'app/functions/use-for-refs';
import { useI18n } from 'app/functions/use-i18n';
import { Title } from 'app/models/title';
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TableOfContents',
  components: {
    TableOfContentsChapter
  },
  props: {
    title: {
      type: Title,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { t } = useI18n();

    const state = ref<'loading' | 'loaded' | 'failed'>('loading');

    const toc = computed(() => props.title.bookToC);

    const fallback = ref('');

    const loan = APP.patron.loans.find({ titleSlug: props.title.slug });

    const { itemRefs: children, setItemRef } = useForRefs<{ expand: Function; collapse: Function }>(getCurrentInstance());

    const expand = () => {
      for (const child of children) {
        child.expand();
      }
    };

    const collapse = () => {
      for (const child of children) {
        child.collapse();
      }
    };

    const linkable = ref(props.title.mediaType === 'book');

    const expandable = computed(() => {
      return toc.value.chapters?.some((c) => !!c.chapters);
    });

    onMounted(async () => {
      if (props.title.hasODRFormat) {
        try {
          // We freshen the props' title so that it gets updated in place
          await props.title.bookToC.freshen();
        } catch (e) {
          console.error('[TOC] Could not fetch detailed table of contents', e);
        }
      }

      // If we have ToC data, it's always better than the Metadata version
      // even if freshening failed.
      if (toc.value.chapters?.length) {
        state.value = 'loaded';
      } else {
        try {
          fallback.value = await props.title.tableOfContents()
            || t('title.tableOfContents.empty');
          state.value = 'loaded';
        } catch (e) {
          state.value = 'failed';
          console.error('[TOC] Could not fetch fallback table of contents', e);
        }
      }
    });

    return {
      collapse,
      expand,
      expandable,
      fallback,
      linkable,
      loan,
      setItemRef,
      state,
      toc
    };
  }
});
</script>

<style module>
.row {
  color: var(--c-dark-black);
}

.title-container {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--c-darkest-gray);
  position: sticky;
  top: 0;
  background-color: var(--c-lightest-gray);
  z-index: 1;
}

.title {
  font-weight: var(--fw-bold);
}

.actions {
  padding-top: 0.25rem;
}

.action {
  margin-bottom: 10px;
  color: var(--c-light-black);
  cursor: pointer;
  composes: linked from global;
}

.separator {
  margin: 0 10px 0 6px;
  border-left: 1px solid var(--c-darkest-gray);
}

.toggle {
  color: inherit;
  width: 100%;
  cursor: pointer;
}

.chapter-list {
  padding: 0 3px;
}
</style>
