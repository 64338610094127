<template>
  <div :class="$style.container">
    <div>
      <h3>{{ $t('title.publisher') }}</h3>
      <router-link
        :to="publisherLink(title.publisher.id)"
        :class="[$style.publisherLink, 'linked', 'focus-outline']"
      >
        {{ title.publisher.name }}
      </router-link>
    </div>

    <div
      v-for="format in title.formatInformation"
      :key="format.name"
    >
      <h3>{{ format.name }}</h3>
      <table :class="$style.formatInfo">
        <tr v-if="title.duration">
          <th scope="col">
            {{ $t('title.duration') }}
          </th>
          <td> {{ durationString }} </td>
        </tr>

        <tr v-if="format.isbn">
          <th scope="col">
            {{ $t('title.isbn') }}
          </th>
          <td>{{ format.isbn }}</td>
        </tr>

        <tr v-if="format.fileSize">
          <th scope="col">
            {{ $t('title.fileSize') }}
          </th>
          <td>{{ $t('title.fileSizeInKB', { n: Math.ceil(format.fileSize / 1000) }) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang='ts'>
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { MS_SECOND, secondsToHumanDuration } from 'lib/common/time';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAccordionDetails',
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  setup: (props) => {
    const publisherLink = (publisherId: string) => {
      return {
        name: RouteName.Publisher,
        params: {
          publisherId
        }
      };
    };

    const durationString = secondsToHumanDuration(props.title.duration / MS_SECOND, {unitCount: 1, maxUnit: 'hours'});

    return {
      durationString,
      publisherLink
    };
  }
});
</script>

<style module>
.container h3 {
  font-weight: var(--fw-bold);
  margin: 1rem 0;
}

.publisher-link {
  margin-left: 1.5rem;
}

.format-info {
  margin-left: 0.5rem;
  text-align: left;
  border-spacing: 1rem 0.5rem;
  border-collapse: initial;
}
</style>
