<template>
  <form
    :class="$style.container"
    @submit.prevent="apply"
  >
    <section
      :class="$style.contentContainer"
    >
      <fieldset
        v-for="category in categories"
        :key="category.category"
        :aria-label="category.label"
        :class="$style.division"
      >
        <component
          :is="categories.length > 1 ? 'button' : 'header'"
          :id="category.category"
          :type="categories.length > 1 ? 'button' : undefined"
          :class="$style.header"
          :aria-expanded="categories.length > 1 ? category.active : undefined"
          @click="toggleHeader(category)"
        >
          <h2
            :aria-label="$t('filters.ariaLabels.header', { CATEGORY: category.label, NUMBER: category.length })"
          >
            {{ category.label }}
          </h2>
          <span
            class="badge"
            :aria-hidden="true"
          >
            {{ category.length }}
          </span>
          <Icon
            v-if="categories.length > 1"
            name="chevron-down"
            role="presentation"
            :class="[$style.arrow, category.active ? $style.expanded : '']"
          />
        </component>
        <DynamicExpander
          :numElements="appliedByCategory[category.category].length"
          :panelId="`filter-selected-panel-${category.category}`"
          :headerId="category.label"
        >
          <transition-group
            v-if="appliedByCategory[category.category].length"
            tag="ol"
            role="list"
            :aria-label="$t('filters.ariaLabels.appliedFilters')"
            name="list"
            :enterFromClass="$style.listEnter"
            :enterActiveClass="$style.listEnterActive"
            :leaveActiveClass="$style.listLeaveActive"
            :leaveToClass="$style.listLeaveTo"
          >
            <li
              v-for="option in appliedByCategory[category.category]"
              :key="option.id"
              :class="$style.listItem"
            >
              <FilterAppliedButton
                :id="`applied-filter-${option.id}`"
                :appliedOption="option"
                :optionType="category.optionType"
                @clicked="updateSelected"
              />
            </li>
          </transition-group>
        </DynamicExpander>
        <DynamicExpander
          v-if="categories.length > 1"
          :expanded="category.active"
          :numElements="category.active ? category.length : 0"
          :panelId="`filter-subset-panel-${category.category}`"
          :headerId="category.label"
        >
          <FilterList
            :category="category.category"
            :optionType="category.optionType"
            :optionSort="category.sort"
            :options="optionsByCategory[category.category]"
            :applied="appliedByCategory[category.category].map((option) => option.id)"
            @checked="updateSelected"
            @length="(val) => category.length = val"
          />
        </DynamicExpander>
        <FilterList
          v-else
          :category="category.category"
          :optionType="category.optionType"
          :options="optionsByCategory[category.category]"
          :applied="appliedByCategory[category.category].map((option) => option.id)"
          @checked="updateSelected"
          @length="(val) => category.length = val"
        />
      </fieldset>
    </section>
    <footer :class="$style.applyContainer">
      <FormSubmitButton
        :label="$t('filters.apply')"
        :disabled="!changesMade"
        :class="!changesMade ? $style.applyInactive : ''"
        :aria-label="!changesMade ? $t('filters.ariaLabels.applyInactive') : ''"
        @click.prevent="apply"
      />
    </footer>
  </form>
</template>

<script lang="ts">
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FilterAppliedButton from 'app/components/FilterAppliedButton.vue';
import { AppliedFilters, FilterCategoryObject, FilterOptionSort, FilterOptionType } from 'app/components/FilterButton.vue';
import FilterList from 'app/components/FilterList.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useSelectable } from 'app/functions/use-selectable';
import { FilterCategory, FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref } from 'vue';

type CategoryPanel = {
  category: FilterCategory;
  label: string;
  optionType: FilterOptionType;
  sort: FilterOptionSort;
  active: boolean;
  length: number;
};

type CategorizedFilters = {
  [category in FilterCategory]: FilterObject[];
};

export default defineComponent({
  name: 'FilterContent',
  components: {
    FilterList,
    FilterAppliedButton,
    DynamicExpander,
    FormSubmitButton
  },
  props: {
    options: {
      type:  Array as PropType<FilterObject[]>,
      required: true
    },
    categoryObjects: {
      type: Array as PropType<FilterCategoryObject[]>,
      required: true
    }
  },
  emits: [
    'apply'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const categories = ref<CategoryPanel[]>([]);
    props.categoryObjects.forEach((cat) => {
      categories.value.push({
        category: cat.id,
        label: t(`filters.categories.${cat.id}`),
        optionType: cat.optionType,
        sort: cat.sort || 'alphaAsc',
        active: props.categoryObjects.length === 1 ? true : false,
        length: 0
      });
    });

    const changesMade = ref(false);

    //list of ids of already applied options
    const selectedIds = computed(() => props.options.filter((option) => option.selected).map((applied) => applied.id));

    //list of all ids
    const allIds = computed(() => props.options.map((option) => option.id));

    const selectable = useSelectable(allIds, selectedIds.value);

    const optionsByCategory = computed(() => {
      //separate all the options by their category
      const allByCategory = {} as CategorizedFilters;
      categories.value.forEach((cat) => {
        allByCategory[cat.category] = props.options.filter((option) => option.category === cat.category);
        cat.length = allByCategory[cat.category].length;
      });

      return allByCategory;
    });

    //any changes made in children components, update accordingly
    const updateSelected = (option: FilterObject) => {
      //if a FilterAppliedButton was clicked to remove it,
      //shift the keyboard focus from that button to a sibling FilterAppliedButton
      //or if no siblings, shift the focus up to the category header button
      const selectedElement = document.getElementById(`applied-filter-${option.id}`);
      const parent = selectedElement?.parentElement;

      if (document.activeElement === selectedElement) {
        const sibling =
          parent?.nextElementSibling?.children[0]
          || parent?.previousElementSibling?.children[0]
          || document.getElementById(option.category);

        (sibling as HTMLElement)?.focus();
      }

      //to prevent a removed FilterAppliedButton from changing its position due to the position:absolute on leave-active
      if (parent) {
        parent.style.left = parent.offsetLeft + 'px';
        parent.style.top = parent.offsetTop + 'px';
      }

      selectable.toggle(option.id);
      changesMade.value = true;
    };

    const appliedByCategory = computed(() => {
      const selectedByCategory = {} as CategorizedFilters;
      categories.value.forEach((cat) => selectedByCategory[cat.category] = optionsByCategory.value[cat.category]
        .filter((sub) => selectable.isSelected(sub.id))
        .sort((a, b) => cat.sort === 'alphaDesc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name))
      );

      return selectedByCategory;
    });

    const toggleHeader = (category: CategoryPanel) => {
      category.active = !category.active;
      category.length = optionsByCategory.value[category.category].length;
    };

    const apply = () => {
      if (!changesMade.value) { return; }
      //need to have selected ids for each category
      const filters = {} as AppliedFilters;
      categories.value.forEach((cat) => {
        const ids = [] as string[];
        appliedByCategory.value[cat.category].forEach((applied) => ids.push(applied.id));
        filters[cat.category] = ids;
      });

      ctx.emit('apply', filters);
    };

    return {
      categories,
      updateSelected,
      changesMade,
      optionsByCategory,
      appliedByCategory,
      toggleHeader,
      apply
    };
  }
});
</script>

<style module>
.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.content-container {
  padding-bottom: 0;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
  grid-column: 1;
  grid-row: 1;
  margin-bottom: 0.25rem;
}

.header {
  font-weight: var(--fw-bold);
  width: 100%;
  font-size: var(--fs-large-body-head);
  display: flex;
  align-items: center;
}

.arrow {
  height: 1.5rem;
  transition: transform 200ms ease-in-out;
  width: 1.5rem;
  margin-left: auto;
}

.division {
  padding: 1rem 0;
  margin: 0 1rem;
  border-bottom: 2px solid var(--c-light-gray);
}

.content-container fieldset:last-child {
  border-bottom: none;
}

.apply-container {
  bottom: 0;
  position: sticky;
  border-top: 2px solid var(--c-light-gray);
  background-color: var(--c-white);
  padding: 2rem 1rem 1rem;
  grid-column: 1;
  grid-row: 2;
}

.apply-inactive {
  opacity: .6;
  cursor: default;
}

/* rotate the arrow */
.expanded {
  transform: rotate(-180deg);
}

/* transitions for list of selected filters */
.list-item {
  transition: opacity .5s ease, transform .5s ease;
  transition-delay: 100ms; /* small delay to fix transition issue on pre-applied filters */
  display: inline-flex;
}

.list-leave-active {
  position: absolute;
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@media screen and (max-width: 360px /* px-vp-very-narrow */) {
  .apply-container {
    padding: 1rem 1rem 0;
  }
}
</style>
