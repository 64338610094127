<template>
  <div
    :aria-label="expandable ? chapter.title : undefined"
    :class="[$style.container, expanded ? $style.expanded : '']"
  >
    <button
      v-if="expandable"
      :class="$style.arrow"
      :aria-label="$t(`title.tableOfContents.${expanded ? 'collapseChapter' : 'expandChapter'}`)"
      :aria-expanded="expanded"
      @click="expanded = !expanded"
    >
      <Icon name="chevron-right" />
    </button>

    <TitleActionButton
      :title="title"
      :seekTo="{
        type: 'path',
        location: chapter.path
      }"
      :label="chapter.title"
      :class="$style.title"
    />

    <span :class="$style.line"></span>
    <ol
      v-if="expanded"
      :class="$style.subChapters"
    >
      <li
        v-for="sub in chapter.chapters"
        :key="sub.path"
      >
        <TableOfContentsChapter
          :ref="setItemRef"
          :title="title"
          :loan="loan"
          :chapter="sub"
          :depth="depth + 1"
          :maxDepth="maxDepth"
          :linkable="linkable"
        />
      </li>
    </ol>
  </div>
</template>

<script lang='ts'>
import { useForRefs } from 'app/functions/use-for-refs';
import { Loan } from 'app/models/loan';
import { Title } from 'app/models/title';
import { Chapter } from 'app/models/toc';
import { computed, defineComponent, getCurrentInstance, nextTick, ref } from 'vue';
import TitleActionButton from './TitleActionButton.vue';

export default defineComponent({
  name: 'TableOfContentsChapter',
  components: {
    TitleActionButton
  },
  props: {
    title: {
      type: Title,
      required: true
    },
    loan: {
      type: Loan,
      default: undefined as Loan | undefined
    },
    chapter: {
      type: Object as () => Chapter,
      required: true
    },
    depth: {
      type: Number,
      default: 0
    },
    maxDepth: {
      type: Number,
      default: Infinity
    },
    linkable: {
      type: Boolean,
      default: true
    }
  },
  setup: (props, ctx) => {
    const { itemRefs: children, setItemRef } = useForRefs<{ expand: Function }>(getCurrentInstance());

    const expandable = computed(() => {
      return (props.chapter.chapters?.length || 0) > 0
        && props.depth < props.maxDepth;
    });

    const expanded = ref(false);

    const link = ref<HTMLElement | null>(null);


    const expand = async () => {
      if (!expandable.value) {
        return;
      }

      expanded.value = true;

      await nextTick();

      for (const child of children) {
        child.expand();
      }
    };

    const collapse = () => expanded.value = false;

    return {
      collapse,
      expand,
      expanded,
      expandable,
      link,
      setItemRef
    };
  }
});
</script>

<style module>
.container {
  margin-top: 15px;
  display: grid;
  column-gap: 20px;
  grid-column-gap: 20px;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 20px auto auto;
}

.sub-chapters {
  grid-row: 3;
  grid-column: 2;
}

.title {
  grid-row: 1 / span 2;
  grid-column: 2;
  max-width: max-content;
  margin-right: 10px;
}

.line {
  grid-row: 2 / end;
  grid-column: 1;
  justify-self: center;
}

.expanded > .line {
  margin-top: 1rem;
  border-left: 1px solid var(--c-darkest-gray);
}

.arrow {
  align-self: flex-start;
  grid-row: 1;
  grid-column: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: var(--c-dark-gray);
  border-radius: 5px;
  padding: 1px;
  fill: currentColor;
  transform: rotate(0);
  transition: transform 150ms ease;
  margin-top: 3px;
}

.arrow svg {
  width: 18px;
  height: 18px;
}

.expanded > .arrow {
  transform: rotate(90deg);
}
</style>
