<template>
  <span
    :class="$style.annotationLink"
  >
    <Icon name="nav-notes" />

    <router-link
      :to="{ name: 'NoteDetails', params: { titleSlug } }"
      :class="['linked', 'focus-outline']"
    >
      {{ $t('annotations.label') }}
    </router-link>

    <span class="badge">{{ annotationCount }}</span>
  </span>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAnnotationsLink',
  props: {
    titleSlug: {
      type: String,
      required: true
    },
    annotationCount: {
      type: Number,
      required: true
    }
  }
});
</script>

<style module>
.annotation-link {
  display: flex;
  align-items: center;
  color: var(--c-light-black);
  font-weight: var(--fw-medium);
}

.annotation-link svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
  margin-right: .5rem;
}

.annotation-link a {
  color: var(--c-light-black);
  line-height: normal;
}

/* ## TABLET LAYOUT */

@media screen and (max-width: 1024px /* px-vp-very-wide */) {
  .annotation-link {
    justify-content: center;
  }
}
</style>
