<template>
  <button
    type="button"
    :class="$style.selected"
    :aria-label="$t('filters.ariaLabels.removeFilter', { NAME: appliedOption.name })"
    @click="$emit('clicked', appliedOption)"
  >
    <span
      v-if="optionType === 'color'"
      :class="$style.indicator"
      :style="`--highlight-color: var(--highlight-color-${appliedOption.id})`"
    ></span>
    {{ appliedOption.name }}
    <Icon
      name="dismiss"
      role="presentation"
      :class="$style.dismiss"
    />
  </button>
</template>

<script lang="ts">
import { FilterOptionType } from 'app/components/FilterButton.vue';
import { FilterObject } from 'app/models/filter-object';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FilterAppliedButton',
  props: {
    appliedOption: {
      type: Object as PropType<FilterObject>,
      default: undefined
    },
    optionType: {
      type: String as PropType<FilterOptionType>,
      default: 'text'
    }
  },
  emits: [
    'clicked'
  ]
});
</script>

<style module>
.selected {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  padding: 0.25rem 0.5rem;
  font-size: var(--fs-metadata);
  margin: 0.25rem 0.5rem 0.25rem 0;
  composes: focus-outline from global;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.dismiss {
  height: 0.6rem;
  width: 0.6rem;
  padding-left: 0.5rem;
  fill: var(--c-darkest-gray);
}

.indicator {
  composes: color-indicator from global;
  background-color: var(--highlight-color);
}
</style>
