<template>
  <ol v-if="volumes">
    <li
      v-for="volume in volumes"
      :key="volume.slug"
      :class="$style.volume"
    >
      <TitleCard
        :title="volume"
        :borderless="true"
      />
    </li>
  </ol>
  <p
    v-else
    :class="$style.loading"
  >
    <Icon
      name="spinner"
      :aria-label="$t('general.loading')"
    />
  </p>
</template>

<script lang='ts'>
import TitleCard from 'app/components/cards/TitleCard.vue';
import { Series } from 'app/models/series';
import { Title, TitleRecord } from 'app/models/title';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAccordionVolumes',
  components: {
    TitleCard
  },
  props: {
    series: {
      type: Series,
      required: true
    }
  },
  setup: (props) => {
    const volumes = ref<TitleRecord[] | null>(null);

    onMounted(async () => {
      const volumeObjects = await props.series.getAllTitleObjects();

      volumes.value = props.series.items
        .map((item) => volumeObjects[item.id])
        .filter((volume) => !!volume)
        .sort(Title.SORT_FUNCTIONS.seriesRank);
    });

    return {
      volumes
    };
  }
});
</script>

<style module>
.loading {
  width: 100%;
  height: 10rem;
  margin: auto;
  position: relative;
}

.loading svg {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.volume:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
