<template>
  <Card
    :item="cardItem"
    :link="link"
  >
    <template #cover>
      <SeriesCover
        :series="series"
        :width="compact ? seriesCoverSizes.seriesCardCompact : seriesCoverSizes.seriesCard"
      />
    </template>

    <template #actions>
      <SeriesCardOptions
        :series="series"
        :link="link"
      />
    </template>
  </Card>
  <!-- eslint-enable -->
</template>

<script lang='ts'>
import { seriesCoverSizes } from 'app/base/constants';
import SeriesCover from 'app/components/SeriesCover.vue';
import Card, { CardMetadata } from 'app/components/cards/Card.vue';
import SeriesCardOptions from 'app/components/cards/SeriesCardOptions.vue';
import { useI18n } from 'app/functions/use-i18n';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { listFormatter } from 'app/i18n/list-formatter';
import { Series } from 'app/models/series';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';

  /**
   * Displays a series based card
   * @displayName SeriesCard
   */
  export default defineComponent({
  name: 'SeriesCard',
  components: {
    Card,
    SeriesCardOptions,
    SeriesCover
  },
  props: {
    /**
     * @param series - The series to display in card form
     */
    series: {
      type: Series,
      required: true
    }
  },
  setup: (props, ctx) => {
    const link = { name: RouteName.SetDetails, params: { seriesId: props.series.id?.toString() } };
    const { locale } = useI18n();
    const { windowWidth } = useWindowSize();
    const compact = computed(() => windowWidth.value <= Breakpoint.Narrow);

    const cardItem = computed(() => {
      const metadata: CardMetadata[] = [];

      metadata.push({ label: 'series.volumes', value: props.series.items.length.toString()});

      const authors = props.series.creators?.Author || [];
      const authorNames = authors.map((author) => author.name);
      const authorsString = listFormatter(authorNames, locale);

      return {
        id: props.series.id,
        title: props.series.title,
        authors: authorsString,
        metadata: metadata
      };
    });


    return {
      cardItem,
      compact,
      seriesCoverSizes,
      link
    };
  }
});
</script>
