import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertListEmptyState = _resolveComponent("AlertListEmptyState")!
  const _component_AlertCard = _resolveComponent("AlertCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.content)
  }, [
    (_ctx.alertsWithMetadata.length === 0)
      ? (_openBlock(), _createBlock(_component_AlertListEmptyState, { key: 0 }))
      : (_openBlock(), _createBlock(_TransitionGroup, {
          key: 1,
          tag: "ol",
          role: "list",
          "aria-label": _ctx.$t('alert.header'),
          name: "list",
          enterFromClass: _ctx.$style.listEnter,
          enterActiveClass: _ctx.$style.listEnterActive,
          leaveActiveClass: _ctx.$style.listLeaveActive,
          leaveToClass: _ctx.$style.listLeaveTo,
          class: _normalizeClass(_ctx.$style.list)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alertsSorted, (alert, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: alert.id,
                class: _normalizeClass(_ctx.$style.listItem)
              }, [
                _createVNode(_component_AlertCard, {
                  alertWithMetadata: alert,
                  onViewDetails: (alert) => _ctx.$emit('view-details', alert),
                  onClear: ($event: any) => (_ctx.clearAlert(alert.id, index))
                }, null, 8, ["alertWithMetadata", "onViewDetails", "onClear"])
              ], 2))
            }), 128))
          ], undefined, true),
          _: 1
        }, 8, ["aria-label", "enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass", "class"]))
  ], 2))
}