import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { useAlertGeneration } from 'app/functions/use-alert-generation';
import { useAlertsSurvey } from 'app/functions/use-alerts-survey';
import { useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { AlertsSurveySymbol, AlertsWithMetadataSymbol, SubscriptionsWithMetadataSymbol } from 'app/keys/injection-keys';
import { provide } from 'vue';

export function useGlobalAlerts() {
  const alertsSurveyContext = useAlertsSurvey();
  provide(AlertsSurveySymbol, alertsSurveyContext);

  const subscriptionsWithMetadataContext = useSubscriptionsWithMetadata();
  provide(SubscriptionsWithMetadataSymbol, subscriptionsWithMetadataContext);

  const alertsWithMetadataContext = useAlertsWithMetadata();
  provide(AlertsWithMetadataSymbol, alertsWithMetadataContext);

  const { startAlertGeneration } = useAlertGeneration(subscriptionsWithMetadataContext, alertsWithMetadataContext);
  const startAlertGenerationSoon = C.debounce(() => { startAlertGeneration(); }, 5 * C.MS_SECOND);

  if (APP.patron.accountId) {
    startAlertGenerationSoon();
    alertsSurveyContext.checkSurvey();
  } else {
    APP.events.on('patron:accountId:acquired', () => {
      startAlertGenerationSoon();
      alertsSurveyContext.checkSurvey();
    });
  }
}
