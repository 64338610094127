import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style.exclamation)
    }, _toDisplayString(_ctx.$t('alert.survey.exclamation')), 3),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('alert.survey.request')), 1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.actions)
    }, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.$style.link, 'focus-outline', 'dark']),
        href: _ctx.link,
        target: "_blank",
        "aria-label": _ctx.$t('alert.survey.linkAriaLabel'),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.acknowledgeAndAnnounce && _ctx.acknowledgeAndAnnounce(...args)))
      }, _toDisplayString(_ctx.$t('alert.survey.link')), 11, _hoisted_1),
      _createElementVNode("button", {
        class: _normalizeClass([_ctx.$style.dismissButton, 'focus-outline']),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.acknowledgeAndAnnounce && _ctx.acknowledgeAndAnnounce(...args)))
      }, _toDisplayString(_ctx.$t('general.dismiss')), 3)
    ], 2)
  ], 2))
}