<template>
  <div>
    <button
      type="button"
      :class="$style.filter"
      :aria-label="$t('filters.ariaLabels.button', { NUMBER: filterCount })"
      aria-haspopup="dialog"
      @click="showFilterSidebar = true"
    >
      <span
        v-if="!iconOnly"
        :class="$style.text"
      >
        {{ $t('filters.header') }}
      </span>
      <Icon
        name="refine-list"
        :class="$style.icon"
      />
      <span
        v-if="filterCount"
        :class="$style.count"
      >{{ filterCount }}</span>
    </button>
    <FilterSidebar
      v-if="showFilterSidebar"
      :options="options"
      :categoryObjects="categoryObjects"
      @close="showFilterSidebar = false"
      @apply="applyFilters"
    />
  </div>
</template>

<script lang="ts">
import FilterSidebar from 'app/components/FilterSidebar.vue';
import { AnnotationFilters } from 'app/functions/use-annotation-filters';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import { FilterCategory, FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref } from 'vue';

export type FilterOptionType = 'text' | 'color';

export type FilterOptionSort = 'alphaAsc' | 'alphaDesc';

export type FilterCategoryObject = {
  id: FilterCategory;
  optionType: FilterOptionType;
  sort?: FilterOptionSort;
};

export type AppliedFilters = SubjectFilters & AnnotationFilters;

export default defineComponent({
  components: {
    FilterSidebar
  },
  props: {
    options: {
      type: Array as PropType<FilterObject[]>,
      required: true
    },
    filterType: {
      type: String as PropType<'subject' | 'annotation' | 'export'>,
      required: true
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'input'
  ],
  setup: (props, { emit }) => {
    const showFilterSidebar = ref(false);

    const categoryObjects = (props.filterType === 'annotation'
      ? [ { id: 'Release', optionType: 'text', sort: 'alphaDesc' },
        { id: 'Color', optionType: 'color' } ]
      : props.filterType === 'subject'
      ? [ { id: 'Jurisdiction', optionType: 'text' },
        { id: 'PracticeArea', optionType: 'text' },
        { id: 'Classification', optionType: 'text' },
        { id: 'Subject', optionType: 'text' } ]
      : [ { id: 'Color', optionType: 'color' } ]) as FilterCategoryObject[];

    const filterCount = computed(() => props.options.filter((option) => option.selected).length);

    const applyFilters = (filters: AppliedFilters) => {
      emit('input', filters);
    };

    return {
      categoryObjects,
      filterCount,
      showFilterSidebar,
      applyFilters
    };
  }
});
</script>

<style module>
.filter {
  align-items: center;
  background-color: var(--c-white);
  border-radius: var(--form-border-radius);
  border: 1px solid var(--c-dark-gray);
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  display: flex;
  padding: 0.5rem 1rem;
  position: relative;
}

.text {
  margin-right: 0.25rem;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
}

.count {
  background-color: var(--c-primary-gray);
  border-radius: 50%;
  color: var(--c-white);
  font-size: var(--fs-metadata);
  height: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  width: 1.5rem;
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}
</style>
