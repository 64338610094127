<template>
  <TitleCard
    :title="title"
    :link="detailsLink"
  >
    <template #actions>
      <div
        ref="actions"
        :class="$style.actions"
      >
        <router-link
          :to="detailsLink"
          :class="$style.detailsLink"
        >
          {{ $t('title.notesAction') }}
        </router-link>

        <TitleCardCopyStatus
          v-if="jobsForTitle.jobs.length"
          :title="title"
          :copyJobsState="jobsForTitle"
          :actionsIconsOnly="iconOnly"
        />

        <TagIndicator
          :title="title"
          :class="$style.tags"
        />

        <button
          ref="overflow"
          :class="$style.overflow"
          :aria-label="$t('title.contextMenu.header')"
          aria-haspopup="dialog"
          @click="showContextMenu = true"
        >
          <Icon name="overflow" />
        </button>

        <TitleCardContextMenu
          v-if="showContextMenu"
          :reference="overflow"
          :title="title"
          :subscribeText="subscribeTextLong"
          @close="showContextMenu = false"
          @show:coverview="showCoverView = true"
          @show:priorreleases="showPriorReleases = true"
          @show:tableofcontents="showTableOfContents = true"
          @toggle:subscription="toggleSubscription"
        />

        <CoverView
          v-if="showCoverView"
          :title="title"
          @close="hideOverlay"
        />

        <PriorReleaseSidebar
          v-if="showPriorReleases"
          :title="title"
          @close="hideOverlay"
        />

        <TableOfContentsSidebar
          v-if="showTableOfContents"
          :item="title"
          @close="hideOverlay"
        />
      </div>
    </template>
  </TitleCard>
</template>

<script lang='ts'>
import TitleCard from 'app/components/cards/TitleCard.vue';
import TitleCardCopyStatus from 'app/components/cards/TitleCardCopyStatus.vue';
import TitleCardContextMenu from 'app/components/contextMenus/TitleCardContextMenu.vue';
import CoverView from 'app/components/CoverView.vue';
import PriorReleaseSidebar from 'app/components/PriorReleaseSidebar.vue';
import TableOfContentsSidebar from 'app/components/TableOfContentsSidebar.vue';
import TagIndicator from 'app/components/TagIndicator.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, nextTick, onMounted, PropType, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';

/**
 * @desc - The notes-based implementation of the title card component
 */
export default defineComponent({
  components: {
    PriorReleaseSidebar,
    TableOfContentsSidebar,
    TitleCard,
    TitleCardContextMenu,
    TitleCardCopyStatus,
    CoverView,
    TagIndicator
},
  props: {
    /**
     * @param title - The title to display in card form
     */
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      default: undefined
    }
  },
  setup: (props) => {
    const overflow = ref<HTMLElement | null>(null);
    const actions = ref<HTMLElement | null>(null);

    const showContextMenu = ref(false);
    const showCoverView = ref(false);
    const showPriorReleases = ref(false);
    const showTableOfContents = ref(false);
    const iconOnly = ref<boolean>(false);

    const updateIcons = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      const cutoff = 600;

      iconOnly.value = entry.contentRect.width < cutoff;
    };

    const resizeObserver = new ResizeObserver(updateIcons);

    onMounted(() => {
      if (actions.value) {
        resizeObserver.observe(actions.value);
      }
    });

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'note card');

    const detailsLink = computed<RouteLocationRaw>(() => {
      const route: RouteLocationRaw = {
        name: RouteName.NoteDetails,
        params: { titleSlug: props.title.slug }
      };

      return route;
    });

    const jobsForTitle = computed(() => {
      const filteredJobs = props.copyJobsState?.status === 'loaded'
        ? props.copyJobsState.jobs.filter((job) => job.target_title.title_id === props.title.slug)
        : [];

      return {
        ...props.copyJobsState,
        jobs: filteredJobs
      };
    });

    const hideOverlay = async () => {
      showCoverView.value = false;
      showPriorReleases.value = false;
      showTableOfContents.value = false;
      await nextTick();
      overflow.value?.focus();
    };

    return {
      actions,
      detailsLink,
      hideOverlay,
      iconOnly,
      jobsForTitle,
      overflow,
      showContextMenu,
      showCoverView,
      showPriorReleases,
      showTableOfContents,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
</script>

<style module>
.actions {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  column-gap: 0.5rem;
}

.details-link {
  appearance: none;
  composes: focus-outline from global;
  color: var(--c-primary-blue);
  font-weight: var(--fw-bold);
  font-size: var(--fs-body);
  width: max-content;

  min-width: calc(var(--rem-cover-width) - 2rem);
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: var(--form-border-radius);
  border: 1px solid rgba(var(--c-primary-blue-rgb), .1);
  padding: .5rem 1rem;
  color: var(--c-primary-blue);

  grid-column: 1;
}

.tags {
  grid-column: 3;
}

.overflow {
  padding: 0 1.125rem;
  line-height: 0;
  grid-column: 4;
  background-color: rgba(var(--c-primary-blue-rgb), .05);
  border-radius: 50%;
}

.overflow:hover, .overflow:focus {
  border-radius: 1.5rem;
  background-color: var(--c-light-gray);
}

.overflow svg {
  height: 1.25rem;
  width: 0.625rem;
  fill: var(--c-primary-blue);
}
</style>
