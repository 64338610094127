<template>
  <div>
    <router-link
      v-if="title"
      :to="{
        name: 'TitleDetails',
        params: {
          titleSlug: title.slug
        }
      }"
      :class="$style.volume"
    >
      <span v-html="title.subtitle || title.title"></span>
    </router-link>
    <h2
      :class="$style.title"
    >
      {{ result.chapterTitle || $t('searchThisTitle.unknownChapter') }}
    </h2>

    <ol
      :class="$style.matches"
    >
      <li
        v-for="(match, index) in result.matches"
        :key="index"
      >
        <div
          :class="$style.match"
        >
          <p
            :class="$style.excerpt"
            v-html="match"
          ></p>

          <div
            :class="$style.actions"
          >
            <TitleActionButton
              :title="title"
              :seekTo="{
                type: 'query',
                location: getBestQueryFromExcerpt(match)
              }"
              :label="$t('searchThisTitle.goToPassage')"
              :class="$style.goto"
            />

            <CopyLink
              :linkFunction="() => getShareLink(getMatch(index))"
              :class="$style.action"
            />
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { MatchedTitle, getBestQueryFromExcerpt } from 'app/base/hudson';
import CopyLink from 'app/components/CopyLink.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { defineComponent } from 'vue';

type MatchInfo = {
  titleSlug: string;
  buid: string;
  path: string;
  excerpt: string;
};

export default defineComponent({
  name: 'SearchThisTitleResult',
  components: {
    CopyLink,
    TitleActionButton
  },
  props: {
    result: {
      type: Object as () => MatchedTitle,
      required: true
    },
    title: {
      type: Object,
      default: undefined
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const getMatch = (index: number) => {
      return {
        titleSlug: props.result.titleId.toString(),
        buid: props.result.buid,
        path: props.result.path,
        excerpt: props.result.matches[index]
      } as MatchInfo;
    };

    const getShareLink = async (match: MatchInfo): Promise<string | null> => {
      const query = getBestQueryFromExcerpt(match.excerpt);
      try {
        const anchorResponse = await APP.sentry.searchAnchorByBUID(match.buid, query, match.path);
        if (!anchorResponse?.result?.token) {
          return null;
        }

        return C.parameterizeURL(
          `${APP.client.info.url}/library/${APP.library.baseKey}/open/${match.titleSlug}`,
          {
            a: anchorResponse.result.token
          }
        );
      } catch (e: any) {
        console.error('[SHARE-PASSAGE] Unable to construct anchor link', e);
        APP.sage.submit('error', {
          errorMessage: e.name + ': ' + e.message,
          errorSource: 'StTResult#getShareLink',
          errorData: {
            message: e.name + ': ' + e.message
          },
          submissionContext: {
            library: APP.library
          }
        });

        return null;
      }
    };

    return {
      getBestQueryFromExcerpt,
      getMatch,
      getShareLink
    };
  }
});
</script>

<style module>
.volume {
  padding: 0.25rem;
  margin: 0.25rem;
  display: block;
  color: var(--c-light-black);
  composes: focus-outline from global;
  composes: linked from global;
}

.title {
  font-size: var(--fs-medium);
  position: sticky;
  top: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffffee;
  font-weight: var(--fw-bold);
  backdrop-filter: blur(5px);
}

.matches {
  border-radius: 0.5rem;
  border: 1px solid var(--c-dark-gray);
  overflow: hidden;
  counter-reset: match;
}

.matches li {
  display: flex;
  background-color: var(--c-light-gray);
  counter-increment: match;
}

.matches li:not(:last-child) {
  border-bottom: 1px solid var(--c-dark-gray);
}

.matches li::before {
  content: counter(match);
  color: var(--c-medium-black);
  background-color: var(--c-light-gray);
  padding: 1rem;
  display: block;
  font-variant-numeric: tabular-nums;
}

.match {
  background-color: var(--c-white);
  padding: 1rem;
}

.excerpt em {
  background-color: var(--c-yellow);
  font-weight: var(--fw-bold);
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.75rem;
}

.action,
.goto span {
  color: var(--c-primary-blue);
}

.action {
  composes: linked from global;
}

.action svg {
  display: none;
}
</style>
