import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "badge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_ctx.$style.annotationLink)
  }, [
    _createVNode(_component_Icon, { name: "nav-notes" }),
    _createVNode(_component_router_link, {
      to: { name: 'NoteDetails', params: { titleSlug: _ctx.titleSlug } },
      class: _normalizeClass(['linked', 'focus-outline'])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('annotations.label')), 1)
      ], undefined, true),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.annotationCount), 1)
  ], 2))
}