import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbNavigation = _resolveComponent("BreadcrumbNavigation")!
  const _component_RouteList = _resolveComponent("RouteList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.content)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.stripe)
    }, null, 2),
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_BreadcrumbNavigation, {
          title: _ctx.title,
          breadcrumbList: _ctx.breadcrumb,
          class: _normalizeClass([_ctx.$style.breadcrumb, 'focus-outline', 'dark'])
        }, null, 8, ["title", "breadcrumbList", "class"]),
        _renderSlot(_ctx.$slots, "context")
      ], 2),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.$style.label)
          }, _toDisplayString(_ctx.$t(_ctx.label)), 3))
        : _createCommentVNode("", true),
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.$style.title),
        innerHTML: _ctx.title
      }, null, 10, _hoisted_1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass(_ctx.$style.subtitle),
            innerHTML: _ctx.subtitle
          }, null, 10, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.authorLinks.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.headerLinks)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.authorLabel)
            }, _toDisplayString(_ctx.$t('title.creator', { n: _ctx.authorLinks.length })), 3),
            _createVNode(_component_RouteList, {
              items: _ctx.authorLinks,
              itemClass: ['details-page-author', 'focus-outline', 'dark', 'linked']
            }, null, 8, ["items"])
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.pillar)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.jacket)
      }, [
        _renderSlot(_ctx.$slots, "cover")
      ], 2),
      (_ctx.shownMetadata.length)
        ? (_openBlock(), _createElementBlock("table", {
            key: 0,
            class: _normalizeClass(_ctx.$style.metadata)
          }, [
            _createElementVNode("caption", _hoisted_3, _toDisplayString(_ctx.$t('title.metadata.description')), 1),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shownMetadata, (metadataItem) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: metadataItem.label,
                  class: _normalizeClass(_ctx.$style.metadataRow)
                }, [
                  _createElementVNode("th", {
                    class: _normalizeClass([_ctx.$style.metadataLabel, _ctx.$style.metadataItem]),
                    scope: "row"
                  }, _toDisplayString(_ctx.$t(metadataItem.label)), 3),
                  _createElementVNode("td", {
                    class: _normalizeClass(_ctx.$style.metadataItem)
                  }, _toDisplayString(metadataItem.value), 3)
                ], 2))
              }), 128))
            ])
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "pillar")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.body)
    }, [
      _renderSlot(_ctx.$slots, "body")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.footer)
    }, [
      _renderSlot(_ctx.$slots, "footer")
    ], 2)
  ], 2))
}