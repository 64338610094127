import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AlertSurvey = _resolveComponent("AlertSurvey")!
  const _component_AlertDetails = _resolveComponent("AlertDetails")!
  const _component_AlertList = _resolveComponent("AlertList")!
  const _component_SiteMenu = _resolveComponent("SiteMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      ref: "alertButton",
      "aria-haspopup": "dialog",
      class: _normalizeClass(_ctx.$style.alertButton),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args)))
    }, [
      (_ctx.hasUnreviewedAlerts)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            name: "alert-bell-unreviewed",
            "aria-label": _ctx.$t('alert.labelUnreviewed')
          }, null, 8, ["aria-label"]))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            name: "alert-bell",
            "aria-label": _ctx.$t('alert.label')
          }, null, 8, ["aria-label"]))
    ], 2),
    (_ctx.showMenu)
      ? (_openBlock(), _createBlock(_component_SiteMenu, {
          key: 0,
          heading: _ctx.$t('alert.header'),
          showBack: _ctx.state.status === 'details',
          onBack: _ctx.hideAlertDetails,
          onClose: _ctx.closeMenu
        }, _createSlots({
          default: _withCtx(() => [
            (_ctx.state.status === 'offline')
              ? (_openBlock(), _createElementBlock("section", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.errorDialog)
                }, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('alert.error.header')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('alert.error.offline')), 1)
                ], 2))
              : (_ctx.state.status === 'error')
                ? (_openBlock(), _createElementBlock("section", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style.errorDialog)
                  }, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('alert.error.header')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('alert.error.general')), 1)
                  ], 2))
                : (_ctx.state.status === 'details')
                  ? (_openBlock(), _createBlock(_component_AlertDetails, {
                      key: 2,
                      alert: _ctx.state.alert,
                      onClose: _ctx.closeMenu
                    }, null, 8, ["alert", "onClose"]))
                  : (_ctx.state.status === 'ready')
                    ? (_openBlock(), _createBlock(_component_AlertList, {
                        key: 3,
                        alertsWithMetadata: _ctx.state.alerts,
                        onViewDetails: _ctx.showAlertDetails,
                        onClear: _ctx.deleteAlertAndAnnounce
                      }, null, 8, ["alertsWithMetadata", "onViewDetails", "onClear"]))
                    : (_openBlock(), _createBlock(_component_Icon, {
                        key: 4,
                        name: "spinner",
                        class: _normalizeClass(_ctx.$style.loading),
                        "aria-label": _ctx.$t('general.loading')
                      }, null, 8, ["class", "aria-label"]))
          ], undefined, true),
          _: 2
        }, [
          (_ctx.shouldShowActions)
            ? {
                name: "extra",
                fn: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.actions)
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.links)
                    }, [
                      _createVNode(_component_router_link, {
                        to: _ctx.mySubLink,
                        class: _normalizeClass([_ctx.$style.link, 'focus-outline']),
                        onClick: _ctx.closeMenu
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('alert.manage')), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["to", "class", "onClick"]),
                      _createElementVNode("button", {
                        class: _normalizeClass([_ctx.$style.link, 'focus-outline', { [_ctx.$style.disabled]: !_ctx.allowClearAll }]),
                        disabled: !_ctx.allowClearAll,
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteAllAlertsAndAnnounce && _ctx.deleteAllAlertsAndAnnounce(...args)))
                      }, _toDisplayString(_ctx.$t('alert.clearAll')), 11, _hoisted_1)
                    ], 2),
                    (!_ctx.surveyAcknowledged)
                      ? (_openBlock(), _createBlock(_component_AlertSurvey, { key: 0 }))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["heading", "showBack", "onBack", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}