<template>
  <div :class="$style.container">
    <div class="notch"></div>
    <header :class="$style.header">
      <span>
        <h1>{{ header }}</h1>
        <Icon
          v-if="icon"
          :name="icon"
          role="presentation"
          :class="$style.icon"
        />
      </span>
      <span>
        <slot name="extra"></slot>
        <button
          data-close_focus
          :class="[$style.close, 'linked']"
          @click="$emit('close')"
        >
          {{ $t('general.close') }}
        </button>
      </span>
    </header>

    <div :class="$style.body">
      <!-- reference element for BackToTopButton -->
      <span
        ref="topOfBody"
        :class="$style.emptySpan"
      ></span>
      <slot></slot>
    </div>

    <BackToTopButton
      :show="showBackToTop"
      :scrollTo="topOfBody"
      :focusOn="focusOn"
      @show="(shouldShow) => showBackToTop = shouldShow"
    />
  </div>
</template>

<script lang='ts'>
import BackToTopButton from 'app/components/BackToTopButton.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'InfoOverlay',
  components: {
    BackToTopButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'close'
  ],
  setup: () => {
    const topOfBody = ref<HTMLElement | null>(null);
    const showBackToTop = ref(false);
    const focusOn = '[data-close_focus]';

    return {
      focusOn,
      showBackToTop,
      topOfBody
    };
  }
});
</script>

<style module>
.container {
  --c-header-background: var(--c-lightest-gray);
  --c-body-background: var(--c-lightest-gray);
  background-color: var(--c-body-background);
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.container :global(.notch) {
  background-color: var(--c-header-background);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--c-header-background);
  padding: 1rem;
}

.header h1 {
  font-weight: var(--fw-bold);
  display: inline-block;
  vertical-align: text-bottom;
}

.header svg {
  width: 1.25rem;
  height: 1.25rem;
}

.close {
  color: var(--c-light-black);
  padding: 0.25rem;
}

.body {
  overflow-y: auto;
}

/* Firefox doesn't respect padding-bottom
  * on overflow auto/scroll elements.
  *
  * https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  */
.body > * {
  padding: 1rem;
  padding-bottom: 4rem;
}

.emptySpan {
  padding: 0;
}
</style>
