<template>
  <TextFilter
    v-model="textFilter"
    :hideLabel="true"
    :class="$style.textFilter"
  />
  <ol
    v-if="filteredOptions.length"
    v-bind="$attrs"
    :class="$style.scroll"
    role="list"
    :aria-label="$t('filters.ariaLabels.filterList')"
    :data-filter-category="category"
    @keydown.home.prevent="setFocus(0)"
    @keydown.end.prevent="setFocus(filteredOptions.length-1)"
  >
    <li
      v-for="option in filteredOptions"
      :key="option.id"
    >
      <FormCheckbox
        :class="$style.item"
        :modelValue="selected(option.id) ? 'on' : 'off'"
        :label="option.name"
        :showLabel="true"
        :ariaLabel="option.count ? $t('filters.ariaLabels.nameWithCount', { NAME: option.name, N: option.count }) : option.name"
        @update:modelValue="$emit('checked', option)"
      >
        <template #default>
          <span
            v-if="optionType === 'color'"
            :class="$style.indicator"
            :style="`--highlight-color: var(--highlight-color-${option.id})`"
          ></span>
          {{ option.name }}
          <span
            v-if="option.count"
            class="badge"
          >
            {{ option.count }}
          </span>
        </template>
      </FormCheckbox>
    </li>
  </ol>
  <div
    v-else
    :class="$style.empty"
    :aria-label="$t('filters.ariaLabels.filterList')"
  >
    {{ $t('filters.noResults') }}
  </div>
</template>

<script lang="ts">
import { FilterOptionSort, FilterOptionType } from 'app/components/FilterButton.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { watchTextFiltering } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { FilterCategory, FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'FilterList',
  components: {
    TextFilter,
    FormCheckbox
  },
  props: {
    category: {
      type: String as PropType<FilterCategory>,
      required: true
    },
    optionType: {
      type: String as PropType<FilterOptionType>,
      default: 'text'
    },
    optionSort: {
      type: String as PropType<FilterOptionSort>,
      default: 'alphaAsc'
    },
    options: {
      type: Array as PropType<FilterObject[]>,
      required: true
    },
    applied: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  emits: [
    'checked',
    'length'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const textFilter = ref('');

    const filteredOptions = computed(() => {
      return props.options.filter((sub) => textFilter.value.length > 0
        ? sub.name.toLowerCase().includes(textFilter.value.toLowerCase())
        : true
      ).sort((a, b) => props.optionSort === 'alphaDesc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name));
    });

    const resultCount = computed(() => t('filters.ariaLabels.header', { CATEGORY: props.category, NUMBER: filteredOptions.value.length }));

    watchTextFiltering(textFilter, resultCount);

    const setFocus = (position: number) => {
      const optionList = document.querySelector(`[data-filter-category='${props.category}']`)?.querySelectorAll('input[type="checkbox"]');

      if (optionList) {
        (optionList[position] as HTMLElement).focus();
      }
    };

    const selected = (optionId: string) => {
      return props.applied.includes(optionId);
    };

    watch(() => filteredOptions.value.length, () => {
      ctx.emit('length', filteredOptions.value.length);
    });

    return {
      textFilter,
      filteredOptions,
      setFocus,
      selected
    };
  }
});
</script>

<style module>
.text-filter {
  margin: 0.75rem 0;
}

.scroll {
  max-height: 20rem;
  overflow-y: auto;
  scrollbar-width: thin;
  margin-left: -0.25rem; /* so the left side of the focus outline isn't hidden b/c of overflow: auto */
}

.item {
  padding: 0.25rem;
}

.empty {
  margin: 1rem 0 0 2.5rem;
  color: var(--c-light-black);
}

.indicator {
  composes: color-indicator from global;
  background-color: var(--highlight-color);
}
</style>
