<template>
  <div :class="$style.container">
    <div
      v-if="showTitle"
      :class="$style.header"
    >
      <p
        :class="$style.title"
        v-html="series.title"
      ></p>
      <VolumeSelect
        v-if="volumes.length > 1"
        v-model="title"
        :class="$style.dropdown"
        :volumes="volumes"
      />
    </div>
    <VolumeSelect
      v-else-if="volumes.length > 1"
      v-model="title"
      :class="$style.dropdown"
      :volumes="volumes"
    />
    <TableOfContents
      v-if="title"
      :key="title.slug"
      :title="title"
    />
  </div>
</template>

<script lang='ts'>
import { Dictionary } from 'app/base/common';
import TableOfContents from 'app/components/TableOfContents.vue';
import VolumeSelect from 'app/components/VolumeSelect.vue';
import { Series } from 'app/models/series';
import { Title, TitleRecord } from 'app/models/title';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SeriesTableOfContents',
  components: {
    VolumeSelect,
    TableOfContents
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    titleMap: {
      type: Object as () => Dictionary<TitleRecord>,
      required: true
    },
    appendDropdownToBody: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const volumes = computed(() => {
      return props.series.items
        .map((item) => props.titleMap[item.id])
        .filter((t) => !!t)
        .sort(Title.SORT_FUNCTIONS.seriesRank);
    });
    const title = ref<TitleRecord | null>(volumes.value[0]);

    return {
      volumes,
      title
    };
  }
});
</script>

<style module>
.container {
  display: grid;
  grid-row-gap: 15px;
}

.dropdown {
  max-width: max-content;
}

.header {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--c-darkest-gray);
  position: sticky;
  top: 0;
  background-color: var(--c-lightest-gray);
  z-index: 1;
}

.title {
  font-weight: var(--fw-bold);
  margin-bottom: 0.5rem;
}
</style>
